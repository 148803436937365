import { api } from '.'

export const reviews_api = {
  getReviews: async (
    review_type: 'all' | 'travel' | 'delivery' = 'all',
    offset?: number,
    limit?: number,
  ) => {
    return await api.get(
      `/services/reviews?review_type=${review_type}${offset ? `&offset=${offset}` : ''}${limit ? `&limit=${limit}` : ''}`,
    )
  },
}
