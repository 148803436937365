import Slider from 'react-slick'

import NextArrow from './NextArrow'
import PrevArrow from './PrevArrow'
import FeedbackSliderItem from '../FeedbackSliderItem/FeedbackSliderItem'

import styles from './FeedbackSlider.module.sass'
import { useEffect, useState } from 'react'
import { IReview } from '@interfaces/dto/reviews.dto'
import { reviews_api } from '@api/reviews.api'

const FeedbackSlider = () => {
  const [feedbacks, setFeedbacks] = useState<IReview[]>([
    {
      review_id: 0,
      review_rating: 0,
      review_text: '',
      review_type: 'all',
      reviewer_name: '',
      created_at: '',
    },
  ])
  const fetchReviews = async () => {
    try {
      const { data } = await reviews_api.getReviews('all', 12)
      if (data.reviews) {
        setFeedbacks(data.reviews)
      }
    } catch (err) {
      throw new Error(err)
    }
  }

  useEffect(() => {
    fetchReviews()
  }, [])
  const settings = {
    customPaging: function () {
      return (
        <a>
          <div
            style={{
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              margin: '0 5px',
            }}
          ></div>
        </a>
      )
    },
    infinite: true,
    slidesToShow: 2,
    speed: 500,
    rows: 2,
    slidesPerRow: 1,
    dots: false,
    // autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: 'slick-dots slick-thumb',
        },
      },
    ],
  }

  return (
    <div className={styles.container}>
      <Slider {...settings}>
        {feedbacks.map(
          ({
            review_id,
            reviewer_name,
            review_rating,
            reviewer_avatar,
            review_text,
          }) => (
            <FeedbackSliderItem
              key={review_id}
              name={reviewer_name}
              stars={review_rating}
              imgUrl={reviewer_avatar}
              comment={review_text}
            />
          ),
        )}
      </Slider>
    </div>
  )
}

export default FeedbackSlider
