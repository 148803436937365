import styles from './TextDescription.module.sass'

import { descriptionMock } from '@mock/homeMock'
import { Accordion } from '@components/UI/Accordion/Accordion'

const TextDescription = () => {
  return (
    <div className={styles.container} id='description'>
      {descriptionMock.map(({ id, title, textContent }) => (
        <Accordion key={id} title={title} content={textContent} />
      ))}
    </div>
  )
}

export default TextDescription
