import Header from '@components/UI/Header/Header'
import { useState, useEffect } from 'react'

import PromotionsSlider from '@components/componentsHome/PromotionsSlider/PromotionsSlider'

import styles from './CurrentPromotions.module.sass'
import { promotions_api } from '@api/promotions'
import { IPromotion } from '@interfaces/dto/promotion'

interface IProps {
  entity?: 'passengerTransportation' | 'cargoTransportation' | ''
}

const formatToSlider = (promotions: IPromotion[]) => {
  return promotions?.map((promotion) => ({
    title: promotion.promotion_title,
    text: promotion.promotion_text,
    imgUrl: promotion.promotion_photo,
    id: promotion.promotion_id,
  }))
}

const CurrentPromotions = ({ entity = '' }: IProps) => {
  const [promotions, setPromotions] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await promotions_api.getPromotions('all')

      if (!data) return setPromotions([])

      setPromotions(formatToSlider(data.promotions))
    }

    fetchData()
  }, [])

  return (
    <div className={styles.container} id='promotions'>
      <div className={styles.topContainer}>
        <div className={styles.headerContainer}>
          <Header color='blueYellow'>
            Актуальні <span>промоакції</span> та <span>знижки</span>
          </Header>
        </div>
      </div>
      {promotions?.length > 0 && <PromotionsSlider data={promotions} />}
    </div>
  )
}

export default CurrentPromotions
