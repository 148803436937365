import React, { useState } from 'react'
import Image from 'next/image'
import styles from './Accordion.module.sass'
import plusIcon from '@public/icons/plus.svg'
import minusIcon from '@public/icons/minus.svg'

interface IAccordionProps {
  title: string | JSX.Element
  content: string | JSX.Element
}

export const Accordion = ({ title, content }: IAccordionProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setOpen(!open)
  }
  const preventSummaryToggle = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
  }

  return (
    <details open={open} className={styles.accordionContainer}>
      <summary
        className={styles.accordionSummary}
        onClick={preventSummaryToggle}
      >
        {title}
        <button className={styles.accordionBtn} onClick={handleOpen}>
          {open ? (
            <Image
              src={minusIcon}
              width={45}
              height={34}
              alt='opened minus icon'
              className={styles.accordionBtnImg}
            />
          ) : (
            <Image
              src={plusIcon}
              width={30}
              height={30}
              className={styles.accordionBtnImg}
            />
          )}
        </button>
      </summary>
      <div className={styles.accordionContent}>{content}</div>
    </details>
  )
}
