import HeaderWithDescription from '@components/UI/HeaderWithDescription/HeaderWithDescription'
import FeedbackSlider from '@components/UI/components/FeedbackSlider/FeedbackSlider'
import NoiseBg from '../NoiseBg/NoiseBg'

import styles from './Feedbacks.module.sass'
import Header from '@components/UI/Header/Header'

interface IProps {
  header?: string | JSX.Element
  description?: string
  svgUrl?: string
  color?: 'yellow' | 'blue' | 'blueYellow'
  dividerColor?: 'yellow' | 'blue' | 'blueYellow'
  isPaddingTop?: boolean
  isNoiseBg?: boolean
}

const Feedbacks = ({
  isPaddingTop = true,
  isNoiseBg = false,
  color,
}: IProps) => {
  return (
    <div
      className={isPaddingTop ? styles.containerPadding : styles.container}
      id='feedbacks'
    >
      {isNoiseBg && <NoiseBg />}
      <div className={styles.headerContainer}>
        <Header color={color}>
          відгуки <span>клієнтів</span>
        </Header>
      </div>
      <div className={styles.sliderContainer}>
        <FeedbackSlider />
      </div>
    </div>
  )
}

export default Feedbacks
