import Image from 'next/image'

import SmallHeader from '@components/UI/SmallHeader/SmallHeader'

import styles from './WhyWeItem.module.sass'

interface IProps {
  color?: 'yellow' | 'blue' | 'blueYellow'
  imgUrl: string
  alt: string
  title: string
  description: string
  [key: string]: any
}

const WhyWeItem = ({
  imgUrl,
  alt,
  title,
  description,
  color = 'yellow',
}: IProps) => {
  const stylesDefault = {
    blue: {
      elipsBlur: styles.elipsBlurBlue,
      line: styles.lineBlue,
    },

    yellow: {
      elipsBlur: styles.elipsBlur,
      line: styles.line,
    },

    blueYellow: {
      elipsBlur: styles.elipsBlur,
      line: styles.line,
    },
  }

  return (
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <div className={styles.animationContainer}>
          <div className={`${stylesDefault[color].elipsBlur}`}></div>
          <div className={`${stylesDefault[color].line}`}></div>
        </div>
        <Image src={imgUrl} alt={alt} layout='fill' objectFit='contain' />
      </div>

      <div className={styles.textContainer}>
        <div className={styles.titleContainer}>
          <SmallHeader uppercase>{title}</SmallHeader>
        </div>

        <div className={styles.descriptionContainer}>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </div>
  )
}

export default WhyWeItem
